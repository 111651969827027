import { computed, reactive, ref, watch } from '@/plugins/composition';

const artforms = ref([
  'Visual Arts',
  'Performing Arts',
  'Music/Sound',
  'Film/Media',
  'Technology',
  'Festivals/Events',
  'Writing/Publishing',
  'Education/Workshops',
  'Wellness/Fitness',
  'Multi-Arts',
  'Community',
  'Other',
]);

const orgTypes = ['Sole Trader', 'Not For Profit entity', 'For Profit entity', 'Partnership', 'Trust'];

const formData = reactive({
  organisationName: '',
  organisationType: '',
  organisationAbn: '',

  email: '',
  firstName: '',
  lastName: '',
  address: '',
  suburb: '',
  state: '',
  country: '',
  postcode: '',
  phone: '',

  artform: '',
  tcs: true,
  updates: false,
  insurance: 'No',
  password: '',
});

const type = ref<'individual' | 'organisation' | null>('individual');
const typeSelected = computed(() => !!type.value);
const isForOrganisation = computed(() => type.value === 'organisation');
const emailExists = ref(false);

const step = ref(1);
const next = () => step.value++;
const prev = () => step.value--;

watch(isForOrganisation, (val: boolean) => {
  if (!val) {
    formData.organisationAbn = '';
    formData.organisationType = '';
    formData.organisationName = '';
  }
});

export const useRegistrationData = () => {
  return {
    artforms,
    orgTypes,
    formData,
    type,
    typeSelected,
    isForOrganisation,
    emailExists,
    step,
    next,
    prev,
  };
};
